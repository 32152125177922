// ==========================================================================
// Wrappers
// ==========================================================================

//
// slides wrapper
// --------------------------------------------------
.slides-wrapper {
	//background: rgba(255, 255, 0, 0.5);
	z-index: 1;
	width: 100%;
	height: 100%;

	@media (min-width: $screen-md-min) {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		padding: $slides-padding-top $slides-padding-side $slides-padding-bottom;

		&__cropper {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;
		}
	}
}

//
// main wrapper
// --------------------------------------------------
.main-wrapper {
	margin-top: 10px;
	margin-bottom: 10px;

	@media (min-width: $screen-md-min) {
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 7;
		padding: $main-padding-top $main-padding-side $main-padding-bottom;
		overflow: hidden;

		.container {
			height: 100%;
			//overflow: hidden;
		}

		&--is-extended {
			//padding-top: $main-padding-extended-top;
			//padding-bottom: $main-padding-extended-bottom;

			.container {
				width: auto;
				padding-right: $extended-container-padding;
				padding-left: $extended-container-padding;
			}
		}

		&--uses-overlay {
			color: #fff;
			.main-header, .main-content {
				width: 430px;
			}
		}

		@media (min-width: $screen-lg-min) {
			&--uses-overlay {
				.main-header, .main-content {
					//width: 32%;
				}
			}
		}
	}
}

//
// slides wrapper
// --------------------------------------------------
.footer-wrapper {
}

// ==========================================================================
// Containers
// ==========================================================================
.container {
	position: relative;
	//z-index: 9;
	/* direction was moved to the containers because the dlmenu needs the body to be LTR */
	//	direction:rtl;
}


// ==========================================================================
// Contents
// ==========================================================================
.main-header {
	overflow: hidden;
	@media (min-width: $screen-md-min) {
		overflow: visible;
		position: absolute;
		//right: $grid-gutter-width/2;
		bottom: 100%;
	}
}
