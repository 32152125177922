.posts-collections-collection-content {

	.post-item {

		&__title {
			color: $site-brand;
			font-weight: bold;
			font-style: $font-size-h4;
		}

		&__partner a {
			color: inherit;
			text-decoration: underline;
			&:hover {
				color: $site-brand;
			}
		}
		&__label {
			color: $site-brand;
		}

		&__read-more {
			margin-top: 10px;
			padding-right: 40px;
			padding-left: 40px;
			color: $text-color;
		}

		& + .post-item {
			margin-top: 40px;
		}

		@media (min-width: $screen-md-min) {
			width: 70%;
		}

	}

}