.drawer {

	@media (max-width: $screen-sm-max) {
		&__open-btn, &__close-btn {
			display: none;
		}
	}

	@media (min-width: $screen-md-min) {

		&__open-btn, &__close-btn {
			@include transition(all 0.45s ease-in-out);
			.btn {
				line-height: 1;
			}
		}

		&__close-btn {
			position: absolute;
			bottom: 100%;
		}

		&__open-btn {
			position: absolute;
			bottom: 100%;
			visibility: hidden;
			opacity: 0;
			margin-right: -$extended-container-padding;
			z-index: 9;
			.drawer-btn-text {
				display: inline-block;
				padding: 0 7px;
				-webkit-transform: rotate(-90deg) translate(-30px, 31px);
				-ms-transform: rotate(-90deg) translate(-30px, 31px); // IE9 only
				-o-transform: rotate(-90deg) translate(-30px, 31px);
				transform: rotate(-90deg) translate(-30px, 31px);
				background: rgba(0, 0, 0, 0.65);

				//@include rotate(-90deg);
				//@include transform-origin(right top);
				//@include translate(0, 0);
			}

		}
	}

}

.drawer__content {
	@media (min-width: $screen-md-min) {
		@include transition(all 0.45s ease-in-out);
		margin-right: 0;
	}
}