//
// project header / subtype filtering
// --------------------------------------------------
.project-type-header {
	h1 {
		color: $filter-color;
		a {
			color: $filter-color;
			&:hover {
				color: $filter-color-hover;
			}
			&:active, &:focus {
				color: $filter-color-active;
			}

			&.is-active {
				color: $filter-color-active;split
				&:hover {
					color: $filter-color-hover;
				}
			}
		}
	}
}

//
// project block
// --------------------------------------------------
.project-type-content {
	.project-block {
		//background: rgba(240, 200, 125, 0.55);
		display: block;
		margin-bottom: $grid-gutter-width;
		position: relative;
		overflow: hidden;
		.covered {
			height: 29vw;
		}

		span {
			display: block;
		}

		.project-data {
			color: #ccc;
			position: absolute;
			width: 100%;
			height: 100%;
			padding: 15px 10px;
			background: rgb(0, 0, 0);
			background: rgba(0, 0, 0, 0.78);
			top: 100%;
			@include transition(all 0.45s ease-in-out)
		}

		.project-name {
			font-weight: bold;
			color: $site-brand;
		}
		&:hover .project-data {
			top: 0
		}

		@media (max-width: $screen-sm-max) {
			.project-data {
				padding-top: 5px;
				padding-bottom: 5px;
				top: auto;
				bottom: 0%;
				height: auto;
			}
			.project-clients {
				display: none;
			}
		}

		@media (min-width: $screen-sm-min) {
			.covered {
				height: 27vw;
			}
		}

		@media (min-width: $screen-md-min) {
			.covered {
				height: 18vw;
			}
		}

		@media (min-width: $screen-lg-min) {
			.covered {
				height: 181.656px;
			}
		}
	}
}