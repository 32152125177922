.project-content {

	.clients-list {
		margin-bottom: 10px;
		color: $site-brand;
	}

	&__video-link {
		display: inline-block;
		position: relative;
		&:before {
			content: " ";
			display: block;
			background: #000;
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include opacity(0.6);
			transition: all 0.3s ease;
		}
		&:after {
			content: "\e029";
			font-family: 'Glyphicons Halflings';
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			display: block;
			position: absolute;
			z-index: 9;
			top: 50%;
			left: 50%;
			font-size: 60px;
			line-height: 1;
			margin-top: -30px;
			margin-left: -30px;
			color: #fff; //$site-brand;
			transition: all 0.3s ease;
		}

		&:hover {
			&:before {
				@include opacity(0);
			}
			&:after {
				color: $site-brand;
			}
		}
	}

	//
	// about us links
	// --------------------------------------------------
	.about-us-links {
		margin: 20px 0;

		&__label {
			color: $site-brand;
		}

		a {
			color: inherit;
			display: block;
			&:hover {
				color: $site-brand;
			}
		}
	}

}