.slick-list {
	overflow: visible;
}

/* Arrows */

.slick-prev,
.slick-next {
	z-index: 9;
	position: absolute;
	display: block;
	cursor: pointer;
	background: $article-bg;
	color: transparent;
	top: 0;
	padding: $arrows-padding;
	border: none;
	outline: none;

	&:before {
		content: " ";
		position: absolute;
		height: 100%;
		width: $slides-padding-side;
		top: 0;
		background: #fff;
	}

	&:hover, &:focus {
		outline: none;
		.slick-arrow {
			opacity: $slick-opacity-on-hover;
		}
	}
	.slick-arrow {
		font-size: $arrows-font-size;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
	}
}

.slick-prev {
	left: 0; //25px;
	border-right: $client-arrow-border;
	[dir="rtl"] & {
		left: auto;
		border-right: none;
		right: 0; //25px;
		border-left: $client-arrow-border;
	}
	&:before {
		left: -$slides-padding-side;
		[dir="rtl"] & {
			right: -$slides-padding-side;
		}
	}
}

.slick-next {
	right: 0; //25px;
	border-left: $client-arrow-border;
	[dir="rtl"] & {
		left: 0; //25px;
		border-left: none;
		right: auto;
		border-right: $client-arrow-border;
	}
	&:before {
		right: -$slides-padding-side;
		[dir="rtl"] & {
			left: -$slides-padding-side;
		}
	}
}