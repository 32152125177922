/*
* Open Sans Hebrew (Hebrew) http://www.google.com/fonts/earlyaccess
*/

$open-sans-path: "../fonts/openSansHebrew";

@at-root {

	@font-face {
		font-family: 'Open Sans Hebrew';
		font-style: italic;
		font-weight: 300;
		src: url('#{$open-sans-path}/opensanshebrew-lightitalic-webfont.eot');
		src: url('#{$open-sans-path}/opensanshebrew-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
		url('#{$open-sans-path}/opensanshebrew-lightitalic-webfont.woff') format('woff'),
		url('#{$open-sans-path}/opensanshebrew-lightitalic-webfont.ttf') format('truetype');
	}
	@font-face {
		font-family: 'Open Sans Hebrew';
		font-style: normal;
		font-weight: 300;
		src: url('#{$open-sans-path}/opensanshebrew-light-webfont.eot');
		src: url('#{$open-sans-path}/opensanshebrew-light-webfont.eot?#iefix') format('embedded-opentype'),
		url('#{$open-sans-path}/opensanshebrew-light-webfont.woff') format('woff'),
		url('#{$open-sans-path}/opensanshebrew-light-webfont.ttf') format('truetype');
	}
	@font-face {
		font-family: 'Open Sans Hebrew';
		font-style: italic;
		font-weight: 400;
		src: url('#{$open-sans-path}/opensanshebrew-italic-webfont.eot');
		src: url('#{$open-sans-path}/opensanshebrew-italic-webfont.eot?#iefix') format('embedded-opentype'),
		url('#{$open-sans-path}/opensanshebrew-italic-webfont.woff') format('woff'),
		url('#{$open-sans-path}/opensanshebrew-italic-webfont.ttf') format('truetype');
	}
	@font-face {
		font-family: 'Open Sans Hebrew';
		font-style: normal;
		font-weight: 400;
		src: url('#{$open-sans-path}/opensanshebrew-regular-webfont.eot');
		src: url('#{$open-sans-path}/opensanshebrew-regular-webfont.eot?#iefix') format('embedded-opentype'),
		url('#{$open-sans-path}/opensanshebrew-regular-webfont.woff') format('woff'),
		url('#{$open-sans-path}/opensanshebrew-regular-webfont.ttf') format('truetype');
	}
	@font-face {
		font-family: 'Open Sans Hebrew';
		font-style: italic;
		font-weight: 700;
		src: url('#{$open-sans-path}/opensanshebrew-bolditalic-webfont.eot');
		src: url('#{$open-sans-path}/opensanshebrew-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
		url('#{$open-sans-path}/opensanshebrew-bolditalic-webfont.woff') format('woff'),
		url('#{$open-sans-path}/opensanshebrew-bolditalic-webfont.ttf') format('truetype');
	}
	@font-face {
		font-family: 'Open Sans Hebrew';
		font-style: normal;
		font-weight: 700;
		src: url('#{$open-sans-path}/opensanshebrew-bold-webfont.eot');
		src: url('#{$open-sans-path}/opensanshebrew-bold-webfont.eot?#iefix') format('embedded-opentype'),
		url('#{$open-sans-path}/opensanshebrew-bold-webfont.woff') format('woff'),
		url('#{$open-sans-path}/opensanshebrew-bold-webfont.ttf') format('truetype');
	}
	@font-face {
		font-family: 'Open Sans Hebrew';
		font-style: italic;
		font-weight: 800;
		src: url('#{$open-sans-path}/opensanshebrew-extrabold-webfont.eot');
		src: url('#{$open-sans-path}/opensanshebrew-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
		url('#{$open-sans-path}/opensanshebrew-extrabold-webfont.woff') format('woff'),
		url('#{$open-sans-path}/opensanshebrew-extrabold-webfont.ttf') format('truetype');
	}
	@font-face {
		font-family: 'Open Sans Hebrew';
		font-style: normal;
		font-weight: 800;
		src: url('#{$open-sans-path}/opensanshebrew-extrabold-webfont.eot');
		src: url('#{$open-sans-path}/opensanshebrew-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
		url('#{$open-sans-path}/opensanshebrew-extrabold-webfont.woff') format('woff'),
		url('#{$open-sans-path}/opensanshebrew-extrabold-webfont.ttf') format('truetype');
	}

}
