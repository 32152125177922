.covered {
	display: block;
	position: relative;
	height: 100%;
	overflow: hidden;
	background-size: cover;
	background-position: center center;
	@media (min-width: $screen-md-min) {
	}
}
