// ==========================================================================
// Clients
// ==========================================================================
.clients-collection-content {

	.mCSB_container {
		direction: rtl;
	}

	.clients-list-wrapper {
		position: relative;
		padding-bottom: 20px;
	}

	.project-item {
		display: block;
		//font-size: $font-size-h6;
		a {
			display: block;
			color: inherit;
			&:hover {
				color: $site-brand;
			}

			.project-item__name {
				text-decoration: underline;
			}

		}
	}

	@media (max-width: $screen-sm-max) {
		.client + .client {
			margin-top: 40px;
		}
		.slick-prev, .slick-next {
			display: none;
		}
		.client-projects-list {
			display: block;
			margin-top: 10px;
		}
	}

	@media (min-width: $screen-md-min) {

		.clients-body {
			width: 70%;
		}

		.client {
			@include opacity(0.3);
			@include transition(opacity 0.45s ease-in-out);
			position: relative;
			&__name {
				@include grayscale(1);
				@include transition(grayscale 0.45s ease-in-out);
				display: block;
				padding: $client-v-padding $client-h-padding;
				border-right: solid 1px $client-border-color;
				border-left: solid 1px $client-border-color;
				margin-left: -1px;
			}
			&:hover, &:focus {
				outline: none;
			}

			&.is-active {
				@include opacity(1);
				.client__name {
					@include grayscale(0);
				}
				.client-projects-list {
					visibility: visible;
					@include opacity(1);
				}
			}
		}

		.client-projects-list {
			display: block;
			padding: 0 $client-h-padding $client-v-padding;
			visibility: hidden;
			@include opacity(0);
			@include transition(opacity 0.45s ease-in-out);
			position: absolute;
			top: 100%;
			right: 0;
			white-space: nowrap;
		}

	}
}
