.projects-collection-content {

	.project-type {
		margin-bottom: 25px;

		&__header {
			color: $site-brand;
			font-size: $font-size-h3;
			font-weight: bold;
		}
	}

	.project-item {
		display: block;
		//font-size: $font-size-h6;
		a {
			color: inherit;
			&:hover {
				color: $site-brand;
			}

			.project-item__name {
				text-decoration: underline;
			}

		}
	}
}