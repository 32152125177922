// ==========================================================================
// Main Menu
// ==========================================================================
.main-menu {
	display: block;
	position: relative;
	z-index: 9;
	font-size: 1.2em;
	a {
		color: $menu-color;
		&:hover {
			color: $site-brand;
		}
	}

	&__header {
		background: $logo-bg;
	}

	.nav {
		background: $logo-bg;
		padding: 1em 0;

		.nav {
			display: none;
		}
	}

	.logo-link {
		margin: 0;
		padding: 10px 20px;
		text-align: left;
		display: block;
	}

	@media (min-width: $screen-md-min) {
		position: absolute;
		top: 0;
		margin-top: 10px;
		left: 12%;
		padding: 0 $grid-gutter-width/2;
		.logo-link {
			background: $logo-bg;
			padding: 50px 15px;
			margin-bottom: 2px;
			.logo-img {
				display: block;
				margin: 0 auto;
			}
		}
	}
	@media (min-width: $screen-lg-min) {
		.logo-link {
			padding: 67px 33px;
		}
	}

	//
	// Menu Open item
	// --------------------------------------------------
	.menu-open {
		display: block;
		background: $site-brand;
		padding-bottom: 1px;

		@media (max-width: $screen-sm-max) {
			position: absolute;
			background: none;
			top: $icon-top;
			right: 25px;
			padding-bottom: 0;
			
			&__text {
				display: none;
			}
		}

		@media (min-width: $screen-md-min) {
			&:hover {
				color: $logo-bg;
				.icon-bar {
					background: $logo-bg;
				}
			}
		}
	}

	// Toggle icon
	.toggle-icon {
		display: block;
		float: right;
		padding: 0.3em 0.37em;
		margin-top: 2px;
		background: $site-brand;
		@media (max-width: $screen-sm-max) {
			padding: 0.6em;
			margin-top: 0;
		}
	}
	// Toggle bars
	.icon-bar {
		display: block;
		width: 0.7em;
		height: 1px;
		border-radius: 1px;
		background: $menu-color;

		& + .icon-bar {
			margin-top: 3px;
		}

		@media (max-width: $screen-sm-max) {
			width: 1.43em;
			height: 2px;
			background: #fff;

			& + .icon-bar {
				margin-top: 5px;
			}
		}
	}

	// waze / click-to-call icons
	.waze-icon, .click-to-call-icon {
		position: absolute;
		top: $icon-top;
		background: $site-brand;
		@media (min-width: $grid-float-breakpoint) {
			display: none;
		}
	}
	.waze-icon {
		right: 75px;
	}
	.click-to-call-icon {
		right: 125px;
	}
}

// ==========================================================================
// Sub Menu
// ==========================================================================

.sub-menu {
	margin: 0 -12px 15px;
	padding: 0;
	color: inherit;
	font-size: $font-size-h4;

	@media (min-width: $screen-md-min) {
		color: #fff;
	}

	li {
		float: right;
		display: block;

		a {
			padding: 0 12px;
			color: inherit;
		}

		&.active a, a:hover  {
			color: $site-brand
		}

		& + li {
			&:before {
				float: right;
				content: "//";
				position: relative;
				display: block;
				padding: 0;
			}
		}
	}
}
