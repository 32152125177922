$error-color: #B94A48;

input.parsley-success,
select.parsley-success,
textarea.parsley-success {
	/*color: #468847;*/
	/*background-color: #DFF0D8;*/
	/*border: 1px solid #D6E9C6;*/
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
	color: $error-color !important;
	background-color: saturate(lighten($error-color, 47%), 30%); //#f9efef !important;
	border: 1px solid lighten($error-color, 18%) !important;
}

.parsley-errors-list {
	color: $error-color;
	margin: 2px 0 3px;
	padding: 0;
	list-style-type: none;
	font-size: 1em;
	line-height: 0.9em;
	opacity: 0;
	-moz-opacity: 0;
	-webkit-opacity: 0;

	transition: all .3s ease-in;
	-o-transition: all .3s ease-in;
	-moz-transition: all .3s ease-in;
	-webkit-transition: all .3s ease-in;
}

.parsley-errors-list.filled {
	opacity: 1;
	-moz-opacity: 1;
	-webkit-opacity: 1;
}
