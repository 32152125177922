// ==========================================================================
// Form
// ==========================================================================
.contact-form {
	margin: 20px 0 40px;

	label {
		font-weight: normal;
	}
}

// ==========================================================================
// Map
// ==========================================================================
.map-wrapper {
}