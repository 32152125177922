/* ===== Footer ===== */
/* ========================================================================== */

.footer-wrapper {
	z-index: 999999;
	font-weight: normal;
	margin-top: 2em;
	margin-bottom: 2em;
	font-size: 0.87em;
	color: $footer-color;
	.footer-content {
		padding-top: 3px;
		//border-top: solid 1px $footer-border-color;
	}
	.copyright {
		text-align: center;
		direction: ltr;
	}
	.design-studio {
		//float: right !important;
		text-align: center;
		direction: rtl;
		a {
			color: $footer-color;
			&:hover { color: $link-color; }
		}
	}

	@media (min-width: $screen-md-min) {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		padding-right: $slides-padding-side;
		padding-left: $slides-padding-side;
		margin-top: 1em;
		margin-bottom: 0.5em;

		.copyright {
			text-align: left;
		}
		.design-studio {
			text-align: right;
		}

		}

	@media (min-width: $screen-lg-min) {
		padding-right: $slides-padding-side;
		padding-left: $slides-padding-side;
	}
}
