@media (min-width: $screen-md-min) {
	.it-scroll {
		height: 100%;
		overflow: auto;
		// to put the bar on the right
		direction: ltr;
		// to compensate for the "outside" option of the scrollbar
		//margin-right: 20px;
	}

	.mCSB_container > * {
		direction: rtl;
	}
}