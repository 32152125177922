.about-us-links-collection-content {
	.about-us-link {
		margin-top: 30px;

		p {
			margin: 0;
		}

		&__media {
			font-size: 25px;
			a {
				color: inherit;
				display: block;
				line-height: 1;
			}
		}

		&__header {
			color: $site-brand;
			font-size: $font-size-h4;
			font-weight: bold;
		}

		&__description-header {
			color: $site-brand;
			float: right;
			padding-left: 0.3em;
		}

		&__read-more {
			clear: both;
			padding: 2px 30px;
			margin: 15px 0;
		}

		@media (min-width: $screen-md-min) {
			width: 70%;
		}
	}

}