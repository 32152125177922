//
// slides and container
// --------------------------------------------------
.slides-container {
	background: $article-bg;
	width: 100%;
	height: 100%;
	position: relative;
  	direction: ltr;

	.slide {
		display: block;
		position: relative;

		&__text {
			display: block;
			color: #fff;//$text-color;
			font-size: $font-size-h3;
			position: absolute;
			right: 0px;
			bottom: 0px;
			padding: 10px;
			line-height: 1;
			text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.86);
		}
	}

	@media (max-width: $screen-sm-max) {
		.slide + .slide {
			margin-top: 5px;
		}
		img {
			@include img-responsive()
		}
	}

	@media (min-width: $screen-md-min) {
		.slick-list, .slick-track {
			height: 100%;
		}
		.slide {
			//position: absolute;
			//top: 0%;
			//right: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center center;
			overflow: hidden;

			&__text {
				font-size: $font-size-h1;
				padding: 30px;
			}

			img {
				display: none;
			}
		}
	}
}

//
// slideshow dark overlay
// --------------------------------------------------
.dark-overlay {
	position: absolute;
	display: block;
	height: 100%;
	width: 100%;
	right: 0;
	top: 0;
	z-index: 99;

	//@include gradient-horizontal(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.88));
	//@include gradient-horizontal-three-colors(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), 30%, rgba(0, 0, 0, 0.88));
	@include gradient-horizontal(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.88), 17%);

	@media (max-width: $screen-sm-max) {
		display: none;
	}
}

//
// slideshow navigation
// --------------------------------------------------
.slides-navigation {
	display: none;
	margin: 0 auto;
	position: absolute;
	z-index: 998;
	top: 48.5%;
	width: 100%;

	a {
		position: absolute;
		display: block;
		font-size: 3em;
		padding: 10px;
		margin-top: -10px;
		line-height: 1;
		background: #000;
		color: #fff;
		&.prev {
			left: $slides-padding-side;
		}
		&.next {
			right: $slides-padding-side;
		}
	}

	//@media (max-width: $screen-sm-max) {
	//	display: none;
	//}
}
