/* ===== GENERAL ===== */
/* ========================================================================== */

html, body {
	@media (min-width: $screen-md-min) {
		width: 100%;
		height: 100%;
	}
}

body {
	direction: rtl;
	text-align: right;
	font-weight: 400;
}

ul,
ol {
	padding: 0;
	list-style-position: inside;
}

a:hover, a:focus, a:active {
	outline: 0;
	text-decoration: none;
}


h1, .h1,
h2, .h2,
h3, .h3
h4, .h4,
h5, .h5,
h6, .h6 {
	margin-top: 0;
	//margin-bottom: 0;
}

h1 {
	color: $brand-primary;
}


pre.dev {
	text-align: left;
	direction: ltr;;
}

.goto-link {
	display: block;
	margin-top: 20px;
}

/* ===== mixins ===== */
/* ========================================================================== */
@mixin grayscale($gray) {
	-webkit-filter: grayscale($gray);
	filter: grayscale($gray);
}