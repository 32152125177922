.partner-posts-list {

	margin-bottom: 25px;

	&__label {
		color: $site-brand;
		font-weight: bold;
	}
	a {
		color: inherit;
		text-decoration: underline;
		&:hover {
			color: $site-brand;
		}
	}
}

.partner-back-to-collection {
	margin: 10px 0;
	padding: 10px 0;
	display: block;
}