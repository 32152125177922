.partners-list {
	margin-bottom: 15px;

	.partner-item {
		margin-top: 25px;

		&__name {
			margin: 0;
			font-weight: bold;
			color: $site-brand;
		}

		&__link {
			display: block;
		}
	}

}