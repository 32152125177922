.post-content {

	.post {

		&__header {
			color: $site-brand;
			margin-bottom: 10px;

			a {
				text-decoration: underline;
			}
		}
	}

}