// ==========================================================================
// Case Studies collection
// ==========================================================================
.case_studies-collection-content {
	.project-block {
		margin-bottom: 10px;
		a {
			color: #fff;
			&:hover {
				color: $site-brand;
			}
		}
		&__data {
			display: block;
			background: #000;
			padding: 3px 10px;
		}

		&__name {
			display: block;
			font-size: $font-size-h3;
			font-weight: bold;
		}

		&__clients {
			display: block;
		}

	}
}

// ==========================================================================
// Case Study
// ==========================================================================
.case-study-content {

	.next-step-link {
		font-size: $font-size-h3;
		color: inherit;
		display: block;
		margin: 0.7em 0;
		padding: 0.5em 0;
		&:hover {
			color: $site-brand;
		}
	}
}

