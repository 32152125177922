.selected-projects-collection-content {

	.project-type-block {
		.covered {

		}
		a:before {
			@include transition(all 0.35s ease-out);
			content: " ";
			display: block;
			background: rgba(0, 0, 0, 0.7);
			position: absolute;
			z-index: 9;
			width: 100%;
			height: 100%;
			opacity: 0;
		}
		.name {
			display: block;
			position: absolute;
			bottom: 1.5em;
			right: 0;
			width: 100%;
			padding: 0.3em $grid-gutter-width/2;
			text-align: center;
			z-index: 999;
			color: #fff;
			font-size: $font-size-large;
			&:after {
				content: " ";
				display: block;
				position: absolute;
				background: #000;
				font-weight: bold;
				height: 100%;
				left: 0; //$grid-gutter-width/2;
				right: 0; //$grid-gutter-width/2;
				bottom: 0;
				z-index: 990;
			}
			&__text {
				position: relative;
				z-index: 999;
			}
		}
		&:hover {
			a:before {
				opacity: 1;
			}
		}

		height: 170px;

		@media (min-width: $screen-sm-min) {
			height: 280px;
		}

		@media (min-width: $screen-md-min) {
			height: 300px;
		}

		@media (min-width: $screen-lg-min) {
			height: 500px;
		}
	}
}